
import { defineComponent, nextTick, onMounted, ref, computed } from 'vue'
import dayjs from 'dayjs'
import { usePrint } from '@/use/usePrint'
import { concatAddress } from '@/utils/address'
import { useRouter } from 'vue-router'
import { chunk } from '@/utils/print'
import ProgressLoading from '@/components/core/ProgressLoading.vue'
import { userCurrency } from '@/use/userCurrency'
import { ORDER_PATH } from '@/constants/route'
import * as htmlToImage from 'html-to-image'
import { LOGO_COURIER, COURIER_NAME } from '@/constants/shippingEnum'
import { useShippingStore } from '@/pinia/shipping/shipping.pinia'
import { useMiddleAccount } from '@/use/middleAccount'
import { isIOS } from '@/utils/validate'
import { ORDER_EXPORT_NAME } from '@/constants/orderEnum'

export default defineComponent({
	name: 'ShippingSticker4x6',
	components: {ProgressLoading},
	emits: ['onSuccess'],
	setup(_, { emit }) {
		const {
			sliceSorthingFlash,
			exportToPDF,
			slicePhoneNo,
			productName,
			isVisibleCODAmount,
			isVisibleProduct,
			onMaskPhoneNumber,
			isHeaderBooking,
			isSortingCode,
			isThaiPost,
			isFlash,
			isJAndT,
			isSortingLineCode,
			isNinja,
			onMarkingShipmentPhoneNo,
			isKerry,
			isNinjaAndKerry,
			isSPX,
			spxServiceType,
			isVisibleBoxSizeAndWeight,
		} = usePrint()
		const { currencyMoneyDigits } = userCurrency()
		const newOrderList = ref<any>([])
		const shippingStore = useShippingStore()
		const router = useRouter()
		const multipleRef: any = []
		const setItemRef = (el: any) => {
			if (el) {
				multipleRef.push(el)
			}
		}
		const loading = ref<boolean>(false)
		const countOrder = ref<number>(0)
		const orderID = computed(() => shippingStore.getterOrderListId)
		const { isChannelType, isDealerType } = useMiddleAccount()
		const typeExport = ORDER_EXPORT_NAME.SHIPPING_LABEL

		const saveImageToPDF = async () => {
			try {
				loading.value = true
				const allImageBase64: any = []
				const newArrayHTML = chunk(multipleRef, 5)

				const toJpegOption = { quality: 1, pixelRatio: 2 }
				for (let idx = 0; idx < newArrayHTML.length; idx++) {
					const list = newArrayHTML[idx].map((each: any) => {
						// อันนี้ hack lib ให้ mobile เนื่องจาก lib html-to-image มีปัญหา
						// เลยต้องแก้ตามท่านี้ https://github.com/bubkoo/html-to-image/issues/52
						// ไม่สวย และน่าเกลียดไปหน่อย แต่ทำใจ
						if (isIOS()) {
							return htmlToImage.toJpeg(each, toJpegOption)
								.then(() => {
									return htmlToImage.toJpeg(each ,toJpegOption)
								})
								.then(() => {
									return htmlToImage.toJpeg(each ,toJpegOption)
								})
								.then(() => {
									return htmlToImage.toJpeg(each ,toJpegOption)
								})
						}
						// desktop เหมือนเดิมปกติ
						return htmlToImage.toJpeg(each, toJpegOption)
					})
					const resolvePromise = await Promise.all(list)
					allImageBase64.push(...resolvePromise)
					countOrder.value = allImageBase64.length
				}

				const template: any = {
					basePdf: { width: 100, height: 150 },
					schemas: [
						{
							image0: {
								position: { x: 0, y: 0 },
								width: 100,
								height: 150,
								type: 'image',
							},
						},
					],
				}
				const newOrder = allImageBase64.map((baseImage: any) => {
					return {
						image0: baseImage,
					}
				})
				await exportToPDF('ShippingSticker4x6', newOrder, template)
				loading.value = false
				emit('onSuccess')
			} catch (error) {
				loading.value = false
				emit('onSuccess')
			}
		}

		const styleLogoImage = (item: any) => {
			switch (item.courier.name) {
			case COURIER_NAME.THAIPOST:
				return 'width: 150px; height: 45px'
			case COURIER_NAME.FLASH:
				return 'width: 55px; height: 30px'
			case COURIER_NAME.NINJA_VAN:
				return 'width: 77px; height: 27px; margin-top: 14px'
			case COURIER_NAME.KERRY:
				return 'width: 110px; height: 45px; margin-top: 10px'
			case COURIER_NAME.SPX:
				return 'width: 80px; height: 36px; margin-top: 10px'
			default:
				return 'width: 98px;'
			}
		}

		const styleBoxSize = (item: any,type: string) => {
			if(isVisibleBoxSizeAndWeight(item)){
				if(type === 'table-box'){
					return 'height: 90px;'
				}
				if(type === 'receiver-box'){
					return 'height: 90px;'
				}
				
			}
		}

		const widthBarCode = (item: any) => {
			if (isJAndT(item)) {
				return 2.5
			} else if (isNinja(item)) {
				return 1.5
			}
			return 1.7
		}

		const spanLogoCourier = (item: any) => {
			if ((item.courier.name === COURIER_NAME.THAIPOST && isChannelType.value) || isKerry(item)) {
				return 24
			} else if(item.courier.name === COURIER_NAME.SPX) {
				return 5
			}
			return isSortingCode(item) ? 6 : 12
		}

		onMounted(async () => {
			newOrderList.value = shippingStore.orderList as any
			console.log('newOrderList.value', newOrderList.value)
			if(newOrderList.value && newOrderList.value.length > 0) {
				await nextTick()
				saveImageToPDF()
			} else {
				router.push({path: ORDER_PATH})
			}
		})

		return {
			dayjs,
			newOrderList,
			loading,
			typeExport,
			countOrder,
			orderID,
			LOGO_COURIER,
			COURIER_NAME,
			isChannelType,
			isDealerType,
			setItemRef,
			sliceSorthingFlash,
			concatAddress,
			slicePhoneNo,
			currencyMoneyDigits,
			isVisibleCODAmount,
			productName,
			isVisibleProduct,
			onMaskPhoneNumber,
			isHeaderBooking,
			styleLogoImage,
			isSortingCode,
			isThaiPost,
			isFlash,
			isJAndT,
			isSortingLineCode,
			isNinja,
			widthBarCode,
			onMarkingShipmentPhoneNo,
			spanLogoCourier,
			isKerry,
			isNinjaAndKerry,
			isSPX,
			spxServiceType,
			styleBoxSize,
			isVisibleBoxSizeAndWeight
		}
	},
})
